<template lang="html">
    <section class="reports">
        <section class="container">
            <template v-if="others">
                <section class="grid-3" >
                    <template v-for="(other, key) in others" :key="key">
                        <newVerticalImg  :dataContent="other"></newVerticalImg>
                    </template>
                </section>
                <paginator :pageActual="page" :totalItems="othersMaxItems" @clicked="changePages" @changed="changePages"></paginator>
            </template>
            <div v-else class="container-noResult">
                <p class="title-dest-underline">No hay resultados</p>
            </div>
        </section>
    </section>
</template>

<script lang="js">
import {
    mapGetters,
    mapActions,
    mapMutations
} from 'vuex';
import store from '@/store';
import newVerticalImg from "@/components/news/new-featured-vertical-img.vue";
import paginator from "@/components/paginator.vue";
import utils from "@/utils/utils.js";
export default {
    name: 'reports',
    components: {
        newVerticalImg,
        paginator
    },
    props: ['filterTime'],
    methods: {
        ...mapActions({
            loadOthers: 'contents/loadOthers'
        }),
        ...mapMutations({
            setPageOthers: 'contents/setPageOthers',
        }),
        changePages(item) {
            this.setPageOthers(item)
            this.loadOthers({
                page: this.page,
            });
            utils.LoaderScrollToTop();
        }
    },
    computed: {
        ...mapGetters({
            getPagesOthers: 'contents/getPagesOthers',
            page: 'contents/getOthersViewPage',
            theme: 'contents/getOthersLastFiltersTheme',
            zone: 'contents/getOthersLastFiltersZone'
        }),
        others: function () {
            return store.getters['contents/getOthers'](this.page)
        },
        othersMaxItems() {
            return this.getPagesOthers();
        }
    },
    mounted() {
        this.loadOthers();
    },
    watch: {
        filterTime: function (newVal, oldVal) {
            this.loadOthers();
        }
    }
}
</script>

<style scoped lang="scss">
.reports {
}
</style>